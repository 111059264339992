import { PhoneIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import Button from "common/components/Button";
import Container from "common/components/Container";
import React from "react";

const SaleInfo: React.FC = () => {
  return (
    <Container customClasses="relative">
      <h2 className="text-center text-lg md:text-3xl">
        Zapraszam do zapoznania się z ofertą sprzedaży instrumentów nowych oraz
        używanych 🎹
      </h2>

      <div className="mt-8 flex flex-col justify-center gap-6 md:mt-10 md:flex-row">
        <Button
          customClasses="flex items-center gap-2 justify-center"
          href="tel:+48602294014"
          outlined
        >
          <PhoneIcon className="h-6 w-6" /> Zadzwoń
        </Button>

        <Button
          customClasses="flex items-center gap-2 justify-center !bg-indigo-600"
          to="/sklep"
        >
          <ShoppingCartIcon className="h-6 w-6" /> Sklep internetowy
        </Button>
      </div>

      {/* Blob */}
      <svg
        viewBox="0 0 200 200"
        className="absolute -top-14 z-[-10] scale-150 md:scale-100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-zinc-500 opacity-10"
          d="M32,-46.1C42,-43.4,50.8,-35.4,58.5,-24.9C66.2,-14.4,72.7,-1.5,68.6,8.1C64.4,17.7,49.7,24,41,37.5C32.4,51,29.8,71.8,21.4,75.5C12.9,79.3,-1.3,66.1,-15.1,58.7C-29,51.4,-42.5,50,-46.8,42.1C-51.2,34.3,-46.4,20.2,-47.7,7.2C-49.1,-5.8,-56.6,-17.5,-57.3,-30.4C-58,-43.3,-51.9,-57.3,-41.2,-59.6C-30.5,-61.9,-15.3,-52.5,-2.1,-49.2C11,-45.9,22.1,-48.7,32,-46.1Z"
          transform="translate(100 100)"
        />
      </svg>
    </Container>
  );
};

export default SaleInfo;
