import Container from "common/components/Container";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const About: React.FC = () => {
  return (
    <Container customClasses="py-2 relative">
      <div className="grid grid-cols-1 items-center gap-6 md:grid-cols-2 md:gap-10">
        <StaticImage
          src="../../../static/images/Leszek Ostrowski.jpg"
          alt="Leszek Ostrowski"
          className="h-[280px] rounded-xl lg:h-[400px]"
          imgClassName="rounded-xl"
          objectFit="cover"
          height={400}
          loading="eager"
        />

        <h2 className="text-justify lg:text-2xl">
          Strojeniem i naprawą fortepianów i pianin zajmuję się{" "}
          <b>od 1991 roku</b>. Wieloletnie praktyka oraz kierunkowe
          wykształcenie gwarantują{" "}
          <b>świadczene usług na najwyższym poziomie.</b> Jestem absolwentem
          krakowskiego Liceum Zawodowego dla stroicieli i korektorów Fortepianów
          i Pianin, które ukończyłem z wyróżnieniem. Zdobyta wiedza została
          utrwalona praktykami w Legnickiej Fabryce Fortepianow i Pianin.
        </h2>
      </div>

      {/* Blob */}
      <svg
        width="100%"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute -top-36 z-[-10] scale-150 md:-top-72 md:scale-100"
      >
        <path
          className="z-[-10] fill-zinc-500 opacity-[15%] md:opacity-10"
          d="M48.4,-55.7C64,-44.5,78.9,-30.5,78.1,-16.6C77.2,-2.6,60.5,11.4,48.8,24.9C37.1,38.5,30.3,51.6,19.5,57.1C8.6,62.6,-6.3,60.4,-23.2,57.1C-40.1,53.8,-58.9,49.4,-64,38.1C-69.1,26.9,-60.4,8.9,-57.5,-10.5C-54.5,-29.9,-57.2,-50.7,-48.6,-63.1C-40,-75.6,-20,-79.7,-1.8,-77.5C16.4,-75.4,32.7,-67,48.4,-55.7Z"
          transform="translate(100 100)"
        />
      </svg>
    </Container>
  );
};

export default About;
