import { CalendarIcon, MusicNoteIcon } from "@heroicons/react/outline";
import Container from "common/components/Container";
import React from "react";

interface RentCardProps {
  title: string;
  children: React.ReactNode;
  icon: JSX.Element;
}

const RentCard: React.FC<RentCardProps> = ({ title, children, icon }) => (
  <article className="flex items-center gap-8 rounded-xl bg-zinc-900 p-6 md:p-8">
    <div>{icon}</div>

    <div className="flex flex-col lg:mt-0">
      <p className="text-lg font-semibold">{title}</p>
      <p className="mt-2 text-base">{children}</p>
    </div>
  </article>
);

const Rent: React.FC = () => {
  return (
    <Container>
      <h2 className="text-xl font-semibold lg:text-2xl">
        Wynajem fortepianów i pianin
      </h2>
      <div className="mt-4 grid grid-cols-1 gap-6 md:grid-cols-2 lg:mt-6">
        <RentCard
          title="Jednorazowy"
          icon={
            <MusicNoteIcon className="h-14 w-14 text-zinc-500 sm:h-16 sm:w-16" />
          }
        >
          Idealny na:{" "}
          <b>
            eventy, koncerty kameralne, śluby, wydarzenia kulturalno-rozrywkowe
          </b>
        </RentCard>

        <RentCard
          title="Długoterminowy"
          icon={
            <CalendarIcon className="h-14 w-14 text-zinc-500 sm:h-16 sm:w-16" />
          }
        >
          Idealny dla: <b>szkół, domów kultury, klubów i osób prywatnych</b>
        </RentCard>
      </div>
    </Container>
  );
};

export default Rent;
