import { CogIcon, MusicNoteIcon } from "@heroicons/react/solid";
import Container from "common/components/Container";
import { Link as GatsbyLink } from "gatsby";
import React from "react";

const links = [
  {
    title: "Strojenie i naprawa",
    icon: <MusicNoteIcon className="h-6 w-6 text-purple-300" />,
    path: "/strojenie-i-naprawa",
  },
  {
    title: "Renowacje",
    icon: <CogIcon className="h-6 w-6 text-zinc-300" />,
    path: "/renowacje",
  },
];

const Links: React.FC = () => {
  return (
    <Container>
      <ul className="grid grid-cols-1 gap-6 text-xl font-semibold md:grid-cols-2 lg:text-2xl">
        {links.map((link) => (
          <li key={link.title}>
            <GatsbyLink
              to={link.path}
              className="flex items-center gap-2 rounded-xl bg-zinc-900 px-8 py-4 md:justify-center"
            >
              {link.title}
              {link.icon}
            </GatsbyLink>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Links;
