import { LocationMarkerIcon } from "@heroicons/react/outline";
import Container from "common/components/Container";
import InfoChip from "common/components/InfoChip";
import React from "react";

const Contact: React.FC = () => {
  return (
    <Container customClasses="flex flex-col md:flex-row justify-between gap-10">
      <div>
        <h2 className="text-2xl font-semibold lg:text-3xl">Kontakt</h2>
        <ul className="mt-4 flex flex-col gap-2 text-lg lg:text-xl">
          <li>
            <span className="font-semibold">Email:</span>{" "}
            <a href="mailto:piano4upiano@gmail.com">piano4upiano@gmail.com</a>
          </li>
          <li>
            <span className="font-semibold">Telefon:</span>{" "}
            <a href="tel:+48602294014">602 294 014</a>
          </li>
        </ul>

        <div className="mt-6 flex flex-col gap-6">
          <InfoChip
            color="red"
            icon={<LocationMarkerIcon className="h-5 w-5" />}
            title="Kraków"
            content="ul. Mariana Smoluchowskiego 7"
          />
        </div>

        <p className="mt-4 font-semibold text-zinc-600">
          Godziny pracy są obecnie nieregularne. Proszę o kontakt :)
        </p>
      </div>

      <iframe
        title="Mapa z lokalizacją lokalu Piano4U w Krakowie"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3546.9431014645893!2d19.90597619046658!3d50.07191750360523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165bba77b876af%3A0xf8618e8d1717c43c!2sMariana%20Smoluchowskiego%207%2C%2030-069%20Krak%C3%B3w!5e0!3m2!1sen!2spl!4v1662756414419!5m2!1sen!2spl"
        height="450"
        width="100%"
        className="max-w-2xl rounded-xl border-0 grayscale invert"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </Container>
  );
};

export default Contact;
