import { Transition } from "@headlessui/react";
import { MailIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import Button from "common/components/Button";
import Container from "common/components/Container";
import delay from "common/utils/delay";
import React, { useEffect, useState } from "react";

interface CustomTransitionProps {
  children: React.ReactNode;
  show: boolean;
}

const CustomTransition: React.FC<CustomTransitionProps> = ({
  children,
  show,
}) => {
  return (
    <Transition
      show={show}
      enter="transition ease-out duration-100"
      enterFrom="transform scale-95"
      enterTo="transform scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform scale-100"
      leaveTo="transform scale-75"
    >
      {children}
    </Transition>
  );
};

const Hero: React.FC = () => {
  const [showService, setShowService] = useState<number>(0);
  const [showTransition, setShowTransition] = useState<boolean>(false);

  const handleClick = () => {
    document.getElementById("contact")?.scrollIntoView({ behavior: "smooth" });
  };

  const services: Array<JSX.Element> = [
    <span className="text-orange-300">Strojenie</span>,
    <span className="text-yellow-300">Naprawa</span>,
    <span className="text-green-300">Renowacje</span>,
    <span className="text-indigo-300">Sprzedaż</span>,
    <span className="text-pink-300">Wynajem</span>,
  ];

  let currentService = services[showService];

  useEffect(() => {
    let mounted = true;
    (async () => {
      while (mounted) {
        for (let i = 0; i < services.length; i++) {
          setShowTransition(true);
          setShowService(i);
          await delay(3000);
          setShowTransition(false);
        }
      }
    })();

    return () => (mounted = false);
  }, [services.length]);

  return (
    <Container customClasses="text-center">
      <h1 className="text-3xl font-semibold lg:text-4xl">
        <CustomTransition show={showTransition}>
          <p className="md:mb-2">{currentService}</p>
        </CustomTransition>
        fortepianów i pianin
      </h1>

      <div className="mt-8 flex flex-col items-center justify-center gap-4 sm:flex-row sm:gap-6">
        <Button
          customClasses="flex items-center justify-center gap-2 rounded-xl !bg-indigo-600 p-2 text-lg"
          to="/sklep"
        >
          <ShoppingCartIcon className="h-6 w-6" /> Sklep internetowy
        </Button>

        <Button
          customClasses="flex items-center justify-center gap-2 rounded-xl p-2 text-lg"
          onClick={handleClick}
          outlined
        >
          <MailIcon className="h-6 w-6" /> Kontakt
        </Button>
      </div>
    </Container>
  );
};

export default Hero;
