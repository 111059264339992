import SEO from "common/components/SEO";
import DefaultLayout from "common/layouts/Default";
import React from "react";

import About from "./About";
import Contact from "./Contact";
import Hero from "./Hero";
import Links from "./Links";
import Rent from "./Rent";
import SaleInfo from "./SaleInfo";

const Home = () => {
  return (
    <DefaultLayout>
      <SEO title="Strona główna" />

      <section className="py-2 lg:py-4">
        <Hero />
      </section>
      <section className="py-8 lg:py-14">
        <About />
      </section>
      <section className="py-8 lg:py-14">
        <Links />
      </section>
      <section className="py-8 lg:py-14">
        <SaleInfo />
      </section>
      <section className="py-8 lg:py-14">
        <Rent />
      </section>
      <section id="contact" className="py-8 lg:py-14">
        <Contact />
      </section>
    </DefaultLayout>
  );
};

export default Home;
